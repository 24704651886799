<template>
  <section class="ministry-goals">
    <EditorJSComponent :text="department | getArticle('tasks')" v-if="department.tasks" />
  </section>
</template>

<script>
import DEPARTMENT from "gql/queries/department.graphql";
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "MinistryGoalsPage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: DEPARTMENT,
      })
      .then(({ data }) => {
        store.dispatch("save", data);
      })
      .catch(() => {});
  },
  computed: {
    department() {
      return this.$store.state.department;
    },
  },
  metaInfo: {
    title: "Цели и задачи",
  },
  components: { EditorJSComponent },
};
</script>

<style lang="stylus">
.ministry-goals {
  font-style: normal;
  font-weight: 500;
  font-size: 1.125em;
  line-height: 28px;
  color: var(--dark);
}
</style>
